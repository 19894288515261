import React from "react";
import CryptoJS from "crypto-js";
import Swal from 'sweetalert2';
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
  Spinner
} from "reactstrap";


class Login extends React.Component {
  state = {
    username: '',
    password: '',
    isLoading: false
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
  }

  handleLogin = async (e) => {
    e.preventDefault();
    if(this.state.username=='' || this.state.password == ''){
      alert("All fileds are composary")
      this.setState({
        isLoading: false
      });
    }
    else{
    this.setState({
      isLoading: true
    });

    try {
      const { username, password } = this.state;
      const key = CryptoJS.HmacSHA512(username, "12Union34Sx").toString();

      sessionStorage.setItem("passcode", key);

      const data = {
        username: username,
        action: 'login',
        password: password,
        passcode: key
      }

      const response = await fetch('https://irecharge.com.ng/api/union/login.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
      });
      const jsonResponse = await response.json();

      if (jsonResponse.status === '0') {
        // sessionStorage.setItem("login", JSON.stringify(jsonResponse));
        sessionStorage.setItem("token", jsonResponse.token);
        sessionStorage.setItem("name", jsonResponse.company_name);
        sessionStorage.setItem("isLoggedIn", true);
        Swal.fire({
          icon: 'success',
          text: 'Login successful',
          position: 'top-end',
          showClass: {
            popup: 'animated fadeInDown faster'
          },
          hideClass: {
            popup: 'animated fadeOutUp faster'
          }
        });
        this.setState({
          isLoading: false
        })
        this.props.history.push('/admin/Index');
      } else {
        Swal.fire({
          icon: 'error',
          text: jsonResponse.message,
          position: 'top-end'
        })
        this.setState({
          isLoading: false
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Unable to connect',
        text: 'Please check your internet connection and try again'
      })
    }
      this.setState({
        isLoading: false
      });
    }
  }

  handlePassword = (e) => {
    e.preventDefault();
    this.props.history.push('/auth/reset_password');
  
  }


  render() {
    const { isLoading } = this.state;
    return (
      <>
        <Col lg="5" md="7">
          <Card className="bg-secondary shadow border-0">
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center">            
                <img alt="..." src={require("assets/img/brand/union.png")} height="50px" width="100px"/>
              </div>
              <br></br>
            <div className="text-center text-muted mb-4">
                <small>Sign in with credentials</small>
              </div>
              <Form role="form">
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-single-02 text-grey" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input onChange={this.handleChange} name="username" placeholder="Username" type="text" />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input onChange={this.handleChange} name="password" placeholder="Password" type="password" />
                  </InputGroup>
                </FormGroup>
                <div className="custom-control custom-control-alternative custom-checkbox">
                  <input
                    className="custom-control-input"
                    id=" customCheckLogin"
                    type="checkbox"
                  />
                </div>
                <div>
                  <Spinner color="primary" />
                </div>
                <div className="text-center">
                <Button className="my-4" color="primary" type="button" onClick={this.handleLogin} disabled={ isLoading }>
                { isLoading ? <span ><i className="fas fa-spinner fa-spin"></i>Please wait...</span> : 'Sign In' }
                  </Button>
                  <a className="text-right"
                href="#pablo"
                onClick={e => e.preventDefault()}
              >
                {/* <small onClick={this.handlePassword}>Forgot password?</small> */}
              </a>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </>
    );
  }
}

export default Login;
