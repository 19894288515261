/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// reactstrap components
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
} from "reactstrap";
import React, { useState } from 'react';
import moment from 'moment';
import ReactExport from "react-export-excel";
import { CSVLink } from 'react-csv';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';
import CalendarTodayIcon from '@material-ui/icons/CalendarTodayOutlined';
import PrintIcon from '@material-ui/icons/Print';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import {
  Button,
  ButtonGroup,
  colors,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  TablePagination,
  Typography,
} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import CryptoJS from "crypto-js";

// core components
import Header from "components/Headers/Header.jsx";

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 700
  },
  spinner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%'
  },
  norec: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    flexDirection: 'column'
  },
  search: {
    padding: theme.spacing(3),
    paddingTop: 0,
    paddingLeft: 0,
    display: 'flex',
    justifyContent: 'space-between',
    '@media print': {
      display: 'none'
    }
  },
  searchMobile: {
    padding: theme.spacing(3),
    display: 'flex',
    // flexWrap: 'wrap',
    justifyContent: 'space-between',
    flexDirection: 'column',
    '@media print': {
      display: 'none'
    }
  },
  button: {
    display: 'block',
    marginTop: theme.spacing(2)
  },
  action: {
    display: 'flex',
    justifyContent: 'center'
  },
  createBtn: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(3)
  },
  form: {
    marginTop: theme.spacing(3),
    width: '100%',
    marginRight: theme.spacing(4)
  },
  formMobile: {
    marginTop: theme.spacing(3),
    minWidth: '80%',
    marginRight: theme.spacing(0)
  },
  text: {
    height: '100%'
  },
  createBtnMobile: {
    display: 'block',
    padding: '0 1.5rem',
    marginTop: theme.spacing(6)
  },
  right: {
    display: 'flex',
    width: '40%'
  },
  rightMobile: {
    display: 'flex',
    width: '80%'
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(2),
    width: '90%',
    margin: '3rem auto 3rem'
  },
  contentMobile: {
    flexGrow: 1,
    padding: theme.spacing(0),
    width: '90%',
    margin: '3rem auto 0'
  },
  dates: {
    padding: 100
  },
  calendarTodayIcon: {
    marginRight: theme.spacing(1)
  },
  printButton: {
    color: 'white',
    backgroundColor: colors.cyan[600],
    '&:hover': {
      backgroundColor: colors.cyan[900]
    },
    margin: theme.spacing(3),
    '@media print': {
      display: 'none'
    }
  },
  printIcon: {
    color: colors.white,
    backgroundColor: colors.cyan[600],
    '&:hover': {
      backgroundColor: colors.cyan[900]
    },
    marginRight: theme.spacing(1)
  },
  exportButton: {
    color: colors.white,
    backgroundColor: colors.teal[600],
    '&:hover': {
      backgroundColor: colors.teal[900]
    },
    margin: theme.spacing(3),
    '@media print': {
      display: 'none'
    }
  },
  exportIcon: {
    color: 'white',
    backgroundColor: colors.teal[600],
    '&:hover': {
      backgroundColor: colors.teal[900]
    },
    marginRight: theme.spacing(1)
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    padding: '1rem 1rem 0',
    height: '2rem'
  },
  item: {
    padding: theme.spacing(3),
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      '&:not(:last-of-type)': {
        borderRight: `1px solid ${theme.palette.divider}`
      }
    },
    // [theme.breakpoints.down('sm')]: {
    //   '&:not(:last-of-type)': {
    //     borderBottom: `1px solid ${theme.palette.divider}`
    //   }
    // }
  },
  printContainer: {
    '@media print': {

      marginLeft: -250,
    },
  },
  getBtn: {
    marginLeft: '25px',
    marginTop: '20px',
  }
}));

const Failed = () => {
  const classes = useStyles();
  const [startDate, setStartDate] = useState(
    moment().subtract(240, 'days').format('Y-MM-DD')
  );
  const [endDate, setEndDate] = useState(moment().format('Y-MM-DD'));
  const [selectEdge, setSelectEdge] = useState(null);
  const [calendarDate, setCalendarDate] = useState(moment());
  const [transactions, setTransactions] = useState([]);
  const [transaction, setTransaction] = useState([]);
  const [view, setView] = useState(false);
  const [loading, isLoading] = useState(false)
  const open = Boolean(selectEdge);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCalendarOpen = edge => {
    setSelectEdge(edge);
  };

  const handleCalendarChange = date => {
    setCalendarDate(date);
  };

  const handleCalendarClose = () => {
    setCalendarDate(moment());
    setSelectEdge(null);
  };

  const handleCalendarAccept = date => {
    setCalendarDate(moment());

    if (selectEdge === 'start') {
      setStartDate(moment(date).format('Y-MM-DD'));

      if (moment(date).isAfter(endDate)) {
        setEndDate(moment(date).format('Y-MM-DD'));
      }
    } else {
      setEndDate(moment(date).format('Y-MM-DD'));

      if (moment(date).isBefore(startDate)) {
        setStartDate(moment(date).format('Y-MM-DD'));
      }
    }

    setSelectEdge(null);
  };

  const handleView = (transaction) => {
    setTransaction(transaction);
    setView(true);
  }
  const getFulfilledTransaction = async () => {
    const token = sessionStorage['token'];
    const passcode = CryptoJS.HmacSHA512(token, "12Union34Sx").toString();
    const url = 'https://irecharge.com.ng/api/union/get_failed.php';
    isLoading(true);

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token,
          passcode,
          start_date: startDate,
          end_date: endDate,
          action: 'get_failed'
        })
      });
      const { status, transactions } = await response.json();
      if (status === '0') {
        setTransactions(transactions);
      }
      isLoading(false)
    } catch (error) {
      console.log(error);
    }
  }
  

  React.useEffect(() => {
    getFulfilledTransaction();
  }, []);

  if (loading) {
    return (
      <h6>Loading, please wait</h6>
    )
  }

  return (
    <div className={classes.printContainer}>

      <Header />
      {/* Page content */}

      <Container className="mt--7" fluid>
        {/* Table */}
        {/* Dark table */}
        <Row className="mt-5">
          <div className="col">
            <Card className="bg-default shadow">
              <CardHeader className="bg-transparent border-0 pb-0">
                <h3 className="text-white mb-0">Failed Transactions</h3>
              </CardHeader>
              {!transactions ? <Typography variant="h5" style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', color: '#fff', margin: '2rem'}}>No transactions found</Typography> :
              (<><div className={classes.search}>

                <div>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => window.print()}
                    className={classes.printButton}>
                    <PrintIcon className={classes.printIcon} /> Print
                  </Button>
                  <ExcelFile filename="Failed Transactions" element={
                    <Button
                      variant="outlined"
                      color="primary"
                      className={classes.exportButton}>
                      <SaveAltIcon className={classes.exportIcon} />
                    Export
                    </Button>
                  }>
                    <ExcelSheet data={transactions} name="Failed Transactions">
                      <ExcelColumn label="Date/Time" value="datetime" />
                      <ExcelColumn label="Transaction ID" value="transaction_id" />
                      <ExcelColumn label="Meter Number" value="meter_number" />
                      <ExcelColumn label="Service" value="service" />
                      <ExcelColumn label="Transaction Amount" value="transaction_amount" />
                      <ExcelColumn label="Branch?" value="is_branch" />
                    </ExcelSheet>
                  </ExcelFile>
                  
                </div>
                <div>
                  <ButtonGroup variant="contained" className={classes.createBtn}>
                    <Button onClick={() => handleCalendarOpen('start')}>
                      <CalendarTodayIcon className={classes.calendarTodayIcon} />
                      {startDate}
                    </Button>
                    <Button onClick={() => handleCalendarOpen('end')}>
                      <CalendarTodayIcon className={classes.calendarTodayIcon} />
                      {endDate}
                    </Button>
                  </ButtonGroup>
                  <div>
                    <Button
                      variant="outlined"
                      color="primary"
                      className={classes.getBtn}
                      onClick={getFulfilledTransaction}>
                      Get Report
                  </Button>
                  </div>
                </div>
              </div>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  maxDate={moment()}
                  onAccept={handleCalendarAccept}
                  onChange={handleCalendarChange}
                  onClose={handleCalendarClose}
                  open={open}
                  style={{ display: 'none' }} // Temporal fix to hide the input element
                  value={calendarDate}
                  variant="dialog"
                />
              </MuiPickersUtilsProvider>
              <Table
                className="align-items-center table-dark table-flush"
                responsive
              >
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">Date</th>
                    <th scope="col">ID</th>
                    <th scope="col">Meter</th>
                    <th scope="col">Service provider</th>
                    <th scope="col">Amount</th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  {
                    transactions.map((transaction, i) => {
                      return (
                        <tr>
                          <td>{transaction.datetime}</td>
                          <td>{transaction.transaction_id}</td>
                          <td>{transaction.meter_number}</td>
                          <td>{transaction.service}</td>
                          <td>{transaction.transaction_amount}</td>
                          <td><Button variant="outlined" style={{ color: '#fff', borderColor: '#fff' }} onClick={() => handleView(transaction)}>View</Button></td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </Table>
              <CardFooter className="py-4 bg-default shadow">
                <nav aria-label="...">
                  <TablePagination
                    component="div"
                    count={transactions.length}
                    page={page}
                    onChangePage={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    style={{ color: '#fff' }}
                  />
                </nav>
              </CardFooter>
              <Dialog onClose={() => setView(false)} aria-labelledby="simple-dialog-title" open={view}>
                <DialogTitle id="simple-dialog-title">{`# ${transaction.transaction_id}`}</DialogTitle>
                <List>
                  <ListItem>
                    <Typography variant="caption">Date:</Typography>
                    <ListItemText primary={transaction.datetime} />
                  </ListItem>
                  <ListItem>
                    <Typography variant="caption">ID:</Typography>
                    <ListItemText primary={transaction.transaction_id} />
                  </ListItem>
                  <ListItem>
                    <Typography variant="caption">Meter:</Typography>
                    <ListItemText primary={transaction.meter_number} />
                  </ListItem>
                  <ListItem>
                    <Typography variant="caption">Service:</Typography>
                    <ListItemText primary={transaction.service} />
                  </ListItem>
                  <ListItem>
                    <Typography variant="caption">Amount:</Typography>
                    <ListItemText primary={transaction.transaction_amount} />
                  </ListItem>
                  <ListItem>
                    <Typography variant="caption">Branch?:</Typography>
                    <ListItemText primary={transaction.is_branch} />
                  </ListItem>
                </List>
              </Dialog></>)}
            </Card>
          </div>
        </Row>
      </Container>
    </div>
  );
}


export default Failed;
