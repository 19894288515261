/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Index from "views/Index.jsx";
 import Login from "views/examples/Login.jsx";
import Failed from "views/examples/Failed.jsx";
import ForgotPassword from "views/examples/Password.jsx";
import DisputeResolutions from "./views/examples/DisputeResolutions";
import Fulfilled from "./views/examples/Fulfilled";


var routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-bullet-list-67 text-blue",
    component: Index,
    layout: "/admin"
  },
  {
    path: "/fulfiled",
    name: "Fulfiled Transactions",
    icon: "ni ni-bullet-list-67 text-blue",
    component: Fulfilled,
    layout: "/admin"
  },
  {
    path: "/failed",
    name: "Failed Transactions",
    icon: "ni ni-tv-2 text-primary",
    component: Failed,
    layout: "/admin"
  },
  // {
  //   path: "/dispute-resolution",
  //   name: "Dispute Resolutions",
  //   icon: "ni ni-single-02 text-yellow",
  //   component: DisputeResolutions,
  //   layout: "/admin"
  // },
  {
    path: "/login",
    component: Login,
    layout: "/auth"
  },
  {
    path: "/reset_password",
    component: ForgotPassword,
    layout: "/auth"
  },

  
];
export default routes;
